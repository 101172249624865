import Constants from 'Constants'

export default {
  mainRequiredTestsSet(state) {
    return state.certification.tests.find(
      (test) =>
        test.group.id ===
        Constants.familyEducationCertificationTestGroup.MAIN_REQUIRED,
    )
  },

  requiredTestsSet(state) {
    return state.certification.tests.find(
      (test) =>
        test.group.id ===
        Constants.familyEducationCertificationTestGroup.REQUIRED,
    )
  },

  optionalTestsSet(state) {
    return state.certification.tests.find(
      (test) =>
        test.group.id ===
        Constants.familyEducationCertificationTestGroup.OPTIONAL,
    )
  },
}
