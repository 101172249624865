import { showToast } from '@/helpers/toast'
import ApiOption from 'ApiRest/Api/Option'
import PromocodeGroup from 'ApiRest/Api/PromocodeGroup'
import ApiPromocodeGroupCheckExists from 'ApiRest/Api/PromocodeGroup/CheckExists'
import ApiPromocodeGroupCode from 'ApiRest/Api/PromocodeGroup/Code'
import ApiPromocodeGenerate from 'ApiRest/Api/PromocodeGroup/Generate'
import PromocodeGroupList from 'ApiRest/Api/PromocodeGroup/List'
import ApiPromocodeGroupType from 'ApiRest/Api/PromocodeGroup/Option/Type'

export default {
  async fetchPromocodeGroups({ commit }, params) {
    let response

    try {
      response = await PromocodeGroupList.get(params)

      commit('setPromocodeGroups', response.data)
    } catch (error) {
      // TODO: -> component
      if (error?.response?.data?.message) {
        showToast(error.response.data.message, 'error')
      } else {
        throw error
      }
    }

    return response
  },

  async fetchPromocodeGroupTypes({ commit }) {
    const { data } = await ApiPromocodeGroupType.get()

    commit('setPromocodeGroupTypesValue', data)
  },

  async fetchSchools({ commit }) {
    const { data } = await ApiOption.getSchools()

    commit('setSchools', data)
  },

  async fetchPartners({ commit, state }) {
    const { data } = await ApiOption.getPartners({
      school: {
        id: state.promocodeGroup.school.id,
      },
    })

    commit('setPartners', data)
  },

  async fetchServices({ commit }) {
    const { data } = await ApiOption.getInvoiceServices()

    commit('setServices', data)
  },

  removePromocodeGroup(context, id) {
    return PromocodeGroup.delete(id)
  },

  async fetchPromocodeGroup({ commit, state }) {
    const { data } = await PromocodeGroup.get(state.promocodeGroupId)

    commit('setPromocodeGroup', data)
  },

  async createPromocodeGroup({ commit, state }) {
    const { data } = await PromocodeGroup.post(state.promocodeGroup)

    commit('setPromocodeGroupId', data.id)
  },

  async fetchPromocodeGroupUniquePromocode({ commit }, payload) {
    const { data } = await ApiPromocodeGroupCheckExists.post(payload)

    commit('setPromocodeGroupUniquePromocode', data)
  },

  async updatePromocodeGroup({ commit, state }) {
    const { data } = await PromocodeGroup.patch(
      state.promocodeGroupId,
      state.promocodeGroup,
    )

    commit('setPromocodeGroup', data)
  },

  removePromocodeGroupCode(
    context,
    { promocodeGroupId, promocodeGroupCodeId },
  ) {
    return ApiPromocodeGroupCode.delete(promocodeGroupId, promocodeGroupCodeId)
  },

  async fetchDiscountTypes({ commit }) {
    const { data } = await ApiOption.getDiscountTypes()

    commit('setDiscountTypes', data)
  },

  async fetchGeneratedPromocode({ commit }) {
    const { data } = await ApiPromocodeGenerate.get()

    commit('setPromocodeGroupValue', {
      key: 'code',
      value: data,
    })
  },

  async fetchPaymentPeriods({ commit }) {
    const { data } = await ApiOption.getPaymentPeriods()

    commit('setPaymentPeriods', data)
  },

  async fetchSubjectsCount({ commit }) {
    const { data } = await ApiOption.getSubjectsCount()

    commit('setSubjectsCount', data)
  },
}
