import DownloadHelper from '@/helpers/DownloadHelper'
import ApiOption from 'ApiRest/Api/Option'
import ApiUserStudent from 'ApiRest/Api/User/Student'
import ApiUserStudentAnalytics from 'ApiRest/Api/User/Student/Analytics'
import ApiUserStudentAnalyticsComment from 'ApiRest/Api/User/Student/Analytics/Comment'
import ApiStudentExtramuralCtpStatement from 'ApiRest/Api/User/Student/ExtramuralCtpStatement'
import ApiStudentFullTimeEducationCtpStatement from 'ApiRest/Api/User/Student/FullTimeEducationCtpStatement'
import { ApiStudentOnlineSchoolCtpStatement } from 'ApiRest/Api/User/Student/OnlineSchoolCtpStatement'
import { ApiStudentShowcaseCtpStatement } from 'ApiRest/Api/User/Student/ShowcaseCtpStatement'

export default {
  async getPeriodListData({ commit }) {
    const { data } = await ApiOption.getAcademicYearPreset()

    commit('setOptionsList', {
      path: 'statementPeriodList',
      value: data,
    })
  },

  async fetchStatementOnlineSchool({ state, commit }, statementPeriod) {
    const payload = {
      academicYearPreset: {
        id: statementPeriod.id,
      },

      student: {
        id: state.studentId,
      },
    }

    const { data } = await ApiStudentOnlineSchoolCtpStatement.get(payload)

    commit('setOnlineSchoolStatement', data)
  },

  async fetchStatementShowcase({ state, commit }, statementPeriod) {
    const payload = {
      academicYearPreset: {
        id: statementPeriod.id,
      },

      student: {
        id: state.studentId,
      },
    }

    const { data } = await ApiStudentShowcaseCtpStatement.get(payload)

    commit('setShowcaseStatement', data)
  },

  async fetchStudentDetail({ state, commit }) {
    const { data } = await ApiUserStudent.get(state.studentId)

    commit('setStudent', data)
  },

  async fetchFullTimeEducationStatements({ getters, commit }) {
    const { data } = await ApiStudentFullTimeEducationCtpStatement.get(
      getters.getStatementParametersWithCurrentGroupsOnly,
    )

    commit('setStatementFullTimeEducation', data)
  },

  async fetchExtramuralStatements({ getters, commit }) {
    const { data } = await ApiStudentExtramuralCtpStatement.get(
      getters.getStatementParametersWithCurrentGroupsOnly,
    )

    commit('setStatementExtramuralEducation', data)
  },

  async fetchStudentAnalytics({ getters, commit }) {
    const { data } = await ApiUserStudentAnalytics.get(
      getters.getStatementParameters,
    )

    commit('setAnalytics', data)
  },

  async fetchSaveAnalyticsComment({ state }) {
    const data = {
      student: {
        id: state.student.id,
      },

      comment: state.analytics.student.comment,
    }

    await ApiUserStudentAnalyticsComment.post(data)
  },

  async fetchDownloadFullTimeEducationCertification({ getters }) {
    const response =
      await ApiStudentFullTimeEducationCtpStatement.downloadCertification(
        getters.getStatementParametersWithCurrentGroupsOnly,
      )

    DownloadHelper.downloadBlob(response)
  },

  async fetchDownloadExtramuralCertification({ getters }) {
    const response =
      await ApiStudentExtramuralCtpStatement.downloadCertification(
        getters.getStatementParametersWithCurrentGroupsOnly,
      )

    DownloadHelper.downloadBlob(response)
  },

  async fetchDownloadOnlineSchoolCertification({ getters }) {
    const response =
      await ApiStudentOnlineSchoolCtpStatement.downloadCertification(
        getters.getStatementParametersWithCurrentGroupsOnly,
      )

    DownloadHelper.downloadBlob(response)
  },
}
