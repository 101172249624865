import { set } from 'lodash'
import Vue from 'vue'

export default {
  setOption(state, { key, value }) {
    Vue.set(state.options, key, value)
  },

  /**
   * @param {Object} state
   * @param {Array} payload
   */
  setSettings(state, payload) {
    state.settings = payload
  },

  setSettingValue(state, { path, value }) {
    set(state.settings, path, value)
  },

  setSettingContract(state, { path, value }) {
    set(state.settings.contract, path, value)
  },

  setSettingOffer(state, { path, value }) {
    set(state.settings.offer, path, value)
  },

  setSettingAdvertisement(state, { path, value }) {
    set(state.settings.advertisement, path, value)
  },
}
